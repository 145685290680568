import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from 'components/dist/atoms/Button';
import { useCallback, useEffect, useState } from 'react'
import type { FileRejection } from 'react-dropzone';
import { api } from 'src/api';
import { IndividualRequestDto } from 'src/backend';
import { HEIC_MIME_TYPE, IMAGE_MIME_TYPES, PDF_MIME_TYPES } from 'src/constants/mimes';
import { DriverLicenseImageType } from 'src/constants/person';
import { ActionStatus } from 'src/constants/ui';
import { useCodeReader } from 'src/hooks/use-code-reader'
import { useLicenseKeys } from 'src/hooks/use-license-keys';
import { useMounted } from 'src/hooks/use-mounted';
import { useUser } from 'src/hooks/use-user';
import { Check } from 'src/icons/check';
import {
    driverLicenseImagesSelector,
    getDriverLicenseImages,
    setUploadDriverLicenseIdleState,
    setUploadDriverLicenseLoadingState,
    uploadDriverLicense
} from 'src/slices/person';
import { useDispatch, useSelector } from 'src/store';
import { themeV2 } from 'src/theme/mysherpas-theme-option';
import { filterInvalidFiles, showRejectedDroppedFilesToast } from 'src/utils';
import { fileToDriverLicenseImage } from 'src/utils/file-to-driver-license-image';
import { toast } from 'src/utils/toast';

import { DriverLicenseDropZone } from './upload-driver-license-form/driver-license-dropzone';
import UploadDriversLicenseForm from './upload-driver-license-form/upload-driver-license-form';


interface PropsType {
    onGoToNext?: () => void;
}

export const UploadDriverLicense = (props: PropsType) => {
    // react state
    const [licenseInfo, setLicenseInfo] = useState<IndividualRequestDto>({} as IndividualRequestDto)
    // redux state
    const { user } = useUser();
    const isMounted = useMounted();
    const { pdftronKey } = useLicenseKeys();
    const hasLicenseInfo = licenseInfo && Object.keys(licenseInfo).length > 0
    const dispatch = useDispatch()

    const driverLicenseImages = useSelector(driverLicenseImagesSelector)

    const { decode, decodeOnProgress } = useCodeReader()

    const handleDropFiles = async (type: keyof typeof DriverLicenseImageType, newFiles: File[], rejectedFiles: FileRejection[] = []): Promise<void> => {
        const { acceptedFiles: filteredAcceptedFiles, rejectedFiles: filteredRejectedFiles } = await filterInvalidFiles(newFiles, rejectedFiles);
        showRejectedDroppedFilesToast(filteredRejectedFiles);
        if (filteredAcceptedFiles.length === 0) {
            return;
        }
        dispatch(setUploadDriverLicenseLoadingState(type));
        const file = await fileToDriverLicenseImage(filteredAcceptedFiles[0], type, pdftronKey);
        if (IMAGE_MIME_TYPES.includes(file.type as any)) {
            await handleReadDriverLicenseBarcode(file);
            const individual = await api.getIndividualFromUser(user.id);
            dispatch(uploadDriverLicense({ individualId: individual.id, file: file, type, dto: licenseInfo }));
        } else {
            dispatch(setUploadDriverLicenseIdleState(type));
        }

    };

    const handlePostEmptyDriverLicenseInfo = async () => {
        const individual = await api.getIndividualFromUser(user.id);
        dispatch(uploadDriverLicense({
            individualId: individual.id,
            dto: {
                residentialAddress: null,
                ssn: '',
                usCitizen: true,
                citizenshipCountry: '',
                emailAddress: '',
                legalMiddleName: '',
                mobilePhone: {
                    locale: 'US',
                    value: ''
                },
                maritalStatus: null,
                userIndividual: null,
                companyAddress: null,
                companyName: null,
                companyPhone: null,
                companyWebsite: null,
                mailingAddress: null,
                dob: '',
                fullName: '',
                legalFamilyName: '',
                legalGivenName: '',
                dd: '',
                vehicleClass: '',
                expiration: '',
                eyesColor: '',
                familyName: '',
                givenName: '',
                hairColor: '',
                height: '',
                number: '',
                restrictions: '',
                state: null,
                weight: '',
                complianceType: '',
                countryIdentification: '',
                issueDate: '',
                inventoryControlNumber: '',
                endorsementCodes: '',
                organDonorIndicator: '',
                sex: '',
                employments: [],
                passportNumber: "",
                permanentResidenceCardNumber: "",
                permanentResidentAlien: false,
                placeOfBirth: "",
                residentialAddresses: []
            }
        }));
    }

    const handleReadDriverLicenseBarcode = useCallback(async (file: File | string): Promise<boolean> => {
        const driversLicense = await decode(file);
        if (driversLicense) {
            setLicenseInfo(driversLicense);
            toast({ content: 'Driver license details extracted from card successfully!', type: 'success' });
            return true;
        }
        return false;
    }, [decode]);

    useEffect(() => {
        if (isMounted()) {
            (async () => {
                const individual = await api.getIndividualFromUser(user.id);
                dispatch(getDriverLicenseImages(individual.id));
                if (individual) {
                    try {
                        const individualDl = await api.getADriversLicense(individual.id);

                        if (isMounted()) {
                            const currentAddress = individualDl?.residentialAddresses?.find((address) => address.address.currentAddress);
                            setLicenseInfo({
                                ...individualDl,
                                residentialAddress: currentAddress?.address ?? null,
                            });
                        }
                    } catch (e) { }
                }
            })()
        }
    }, [dispatch, isMounted, user.id])


    const isFrontLoading = (driverLicenseImages.frontUploadStatus === ActionStatus.loading || driverLicenseImages.frontStatus === ActionStatus.loading)
    const isBackLoading = (driverLicenseImages.backUploadStatus === ActionStatus.loading || driverLicenseImages.backStatus === ActionStatus.loading)
    const isDriversLicenseValid = licenseInfo?.number;

    return <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
            <Typography variant='subtitle2' sx={{ color: themeV2.colors.blue[800], mb: 2 }}>
                Drivers License
            </Typography>
            <DriverLicenseDropZone
                onDrop={handleDropFiles.bind(null, DriverLicenseImageType.front)}
                accept={[...IMAGE_MIME_TYPES, ...PDF_MIME_TYPES, HEIC_MIME_TYPE]}
                disabled={isFrontLoading}
                uploaded={!!driverLicenseImages.front}
                imageSrc={driverLicenseImages.front}
                uploading={isFrontLoading}
                title={!!driverLicenseImages.front ? 'Front' : 'Upload Front'}
                hasError={false} />
            <Box sx={{ my: 3 }}>
                {driverLicenseImages.frontUploadStatus === ActionStatus.error &&
                    <Alert severity='error'>
                        <AlertTitle>Uploading Error</AlertTitle>
                        An error happened while uploading the file
                    </Alert>
                }
                {driverLicenseImages.back && !driverLicenseImages.front && decodeOnProgress && !isFrontLoading &&
                    <Alert severity='error'>
                        <AlertTitle>Driver license front</AlertTitle>
                        Please provide an image of the front side of your driving license
                    </Alert>
                }
            </Box>
            <DriverLicenseDropZone
                onDrop={handleDropFiles.bind(null, DriverLicenseImageType.back)}
                accept={[...IMAGE_MIME_TYPES, ...PDF_MIME_TYPES, HEIC_MIME_TYPE]}
                disabled={isBackLoading}
                uploaded={!!driverLicenseImages.back}
                uploading={isBackLoading}
                imageSrc={driverLicenseImages.back}
                title={!!driverLicenseImages.back ? 'Back' : 'Upload Back'}
                hasError={false} />
            <Box sx={{ mt: 3 }}>
                {driverLicenseImages.backUploadStatus === ActionStatus.error &&
                    <Alert severity='error'>
                        <AlertTitle>Uploading Error</AlertTitle>
                        An error happened while uploading the file
                    </Alert>
                }
                {(driverLicenseImages.back && !decodeOnProgress && !hasLicenseInfo) &&
                    <Alert severity='error'>
                        <AlertTitle>Driver license back</AlertTitle>
                        Could not read the back of your Identification, upload a clearer image, or enter your information manually.
                    </Alert>
                }
            </Box>
            {!isDriversLicenseValid && <Typography variant='subtitle2' sx={{ color: themeV2.colors.blue[800] }}>
                Drag and drop <span style={{ color: themeV2.neutral.grey[100] }}>or</span> select card to upload from browser
            </Typography>}
            {isDriversLicenseValid && <Typography variant='subtitle2' sx={{ color: themeV2.colors.green[50], display: 'flex', alignItems: 'center', gap: 1 }}>
                <Check fontSize="small" />  Verified
            </Typography>}
            <Box sx={{
                mt: 3,
                justifyContent: {
                    xs: 'center',
                    sm: 'flex-end'
                },
                display: 'flex'
            }}>
                {(driverLicenseImages.back && !decodeOnProgress && !hasLicenseInfo) &&
                    <Button
                        variant="outline"
                        onClick={handlePostEmptyDriverLicenseInfo}>
                        Continue anyway
                    </Button>}
            </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
            <Typography variant='subtitle2' sx={{ color: themeV2.colors.blue[800], mb: 2 }}>
                Personal Information
            </Typography>
            <UploadDriversLicenseForm
                onSubmitted={props.onGoToNext}
                defaultValues={licenseInfo} />
        </Grid>
    </Grid>

}